
.bg-dirt {
  background-color: $dirty-white;
}

.page-content-wrapper {
  background-color: $light;
  min-height: calc(100vh / 2);
  color: $primary;
}

.page-content-wrapper-x {
  background-color: $ak-blue-dark;
  min-height: calc(100vh / 2);
  color: $ak-blue-light;
}

a {
  color: $ak-blue;

  &:hover {
    color: $ak-blue-light;
  }
}

.video-link.btn-link {
  &:hover {
    color: $light
  }
}

.faq {
  padding: 12px;
  border-radius: 8px;
}

.logo-wrapper {
  width: 150px;
  height: 150px;
  display: inline-block;

  img {
    margin-top: 10px;
    margin-left: -10px;
  }
}

.how-it-works-enter {
  opacity: 0.01;

  &.how-it-works-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
}

.how-it-works-leave {
  opacity: 1;

  &.how-it-works-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
}

.home-plans-wrapper {
  .card {
    position: relative;
    .quantity {
      position: relative;
      .dollar {
        font-size: 22px;
        position: relative;
        top: -28px;
      }
      .price {
        font-size: 60px;
      }
    }
    a.btn {
      position: absolute;
      bottom: 12px;
      left: 12px;
      right: 12px;
    }
  }
}

.btn-outline-light:hover {
  color: $primary;
}

.table-plans {
  h5 {
    margin-bottom: 0;
  }
  p {
    margin-top: .5rem;
    margin-bottom: 0;
  }
}
