.pricing-3-options-section {
  margin-top: 90px;
}
.pricing-3-options-section .header {
  text-align: center;
}
.pricing-3-options-section .header h3 {
  font-size: 24px;
  color: #444;
  line-height: 25px;
  font-weight: 500;
}
.pricing-3-options-section .header p {
  font-size: 16px;
  font-weight: 400;
  color: $ak-blue-light;
}
.pricing-3-options-section .charts {
  margin-top: 60px;
}
.pricing-3-options-section .charts .chart {
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(181, 181, 181, 0.3);
  padding: 20px 40px;
  position: relative;
  text-align: center;
  width: 97%;
  min-height: 315px;
  position: relative;
  top: 20px;
}
@media (min-width: 992px) {
  .pricing-3-options-section .charts .chart.first {
    float: right;
    left: 45px;
  }
  .pricing-3-options-section .charts .chart.last {
    left: -45px;
  }
}
@media (max-width: 991px) {
  .pricing-3-options-section .charts .chart {
    float: none !important;
    left: 0 !important;
    top: 0 !important;
    margin: 0 auto;
    width: 55%;
    margin-bottom: 25px !important;
  }
  .pricing-3-options-section .charts .chart.last {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 600px) {
  .pricing-3-options-section .charts .chart {
    width: 90% !important;
  }
}
.pricing-3-options-section .charts .chart.featured {
  z-index: 999;
}
@media (min-width: 992px) {
  .pricing-3-options-section .charts .chart.featured {
    margin: 0 auto;
    top: 0;
    min-height: 445px;
  }
  .pricing-3-options-section .charts .chart.featured .btn-signup {
    margin-top: 32px;
  }
}
.pricing-3-options-section .charts .chart.featured .popular {
  position: absolute;
  top: 0;
  left: 0;
}
.pricing-3-options-section .charts .chart.featured .quantity {
  margin-top: 15px;
  padding-bottom: 5px;
}
.pricing-3-options-section .charts .chart .plan-name {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #444;
  width: 90%;
  margin: 0 auto;
  margin-top: 5px;
}
.pricing-3-options-section .charts .chart .quantity {
  text-align: center;
  border-bottom: 1px solid #d5d5d5;
  margin-top: 10px;
}
.pricing-3-options-section .charts .chart .quantity .dollar {
  font-size: 19px;
  position: relative;
  top: -18px;
}
.pricing-3-options-section .charts .chart .quantity .price {
  font-size: 49px;
}
.pricing-3-options-section .charts .chart .quantity .period {
  font-size: 17px;
  position: relative;
  top: -8px;
  margin-left: 4px;
}
.pricing-3-options-section .charts .chart .specs {
  margin-top: 20px;
}
.pricing-3-options-section .charts .chart .specs .spec {
  font-size: 15px;
  color: #474747;
  text-align: center;
  font-weight: 300;
  margin-bottom: 13px;
}
.pricing-3-options-section .charts .chart .specs .spec .variable {
  color: #1fbee7;
  font-weight: 500;
}
.pricing-3-options-section .charts .chart .btn-signup {
  margin-top: 20px;
  height: 38px;
}
.pricing-3-options-section .message {
  text-align: center;
  font-size: 13px;
  color: #656565;
  margin-top: 23px;
}
.pricing-3-options-section .faq {
  margin-top: 80px;
}
.pricing-3-options-section .faq .header h3 {
  font-size: 20px;
  color: #454545;
  text-align: center;
  font-weight: 600;
}
.pricing-3-options-section .faq .questions {
  margin-top: 50px;
}
.pricing-3-options-section .faq .questions .question {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .pricing-3-options-section .faq .questions .question {
    width: 90%;
  }
}
.pricing-3-options-section .faq .questions .question strong {
  font-weight: 500;
  font-size: 16px;
  color: #454545;
}
.pricing-3-options-section .faq .questions .question p {
  color: #555;
  margin-top: 10px;
  font-size: 13px;
  line-height: 22px;
}
.pricing-3-options-section .contact {
  margin-top: 40px;
}
.pricing-3-options-section .contact .wrapper {
  background: #f7f8fb;
  border: 1px solid #eaedf7;
  border-radius: 8px;
  padding: 35px 60px;
  margin: 0 auto;
}
.pricing-3-options-section .contact h4 {
  font-size: 15px;
  position: relative;
  color: #666;
  text-align: center;
  font-weight: 500;
  margin: 0;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
}
@media (max-width: 991px) {
  .pricing-3-options-section .contact h4 {
    line-height: 26px;
  }
}
.pricing-3-options-section .contact h4 span {
  position: absolute;
  font-size: 23px;
  top: -5px;
  left: 72px;
  color: #626e7a;
}
@media (max-width: 991px) {
  .pricing-3-options-section .contact h4 span {
    position: relative;
    left: 0;
    top: 5px;
  }
}
.pricing-dark-section {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background: radial-gradient(#36414e, #253241);
  margin-top: 120px;
  padding-top: 70px;
  padding-bottom: 70px;
}
.pricing-dark-section .header h3 {
  font-size: 27px;
  color: #fff;
  line-height: 25px;
  font-weight: 400;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
@media (max-width: 767px) {
  .pricing-dark-section .header h3 {
    font-size: 24px;
    line-height: 29px;
  }
}
.pricing-dark-section .header p {
  font-size: 16px;
  margin-top: 15px;
  color: #fff;
  font-weight: 400;
  line-height: 25px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.pricing-dark-section .charts {
  margin-top: 50px;
}
@media (max-width: 767px) {
  .pricing-dark-section .charts {
    margin-top: 15px;
  }
}
.pricing-dark-section .charts .chart {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(181, 181, 181, 0.3);
  padding: 20px 40px;
  position: relative;
  text-align: center;
  min-height: 315px;
  position: relative;
  top: 37px;
}
@media (max-width: 991px) {
  .pricing-dark-section .charts .chart {
    padding: 20px 10px;
  }
}
@media (max-width: 767px) {
  .pricing-dark-section .charts .chart {
    margin-top: 30px !important;
    top: 0;
  }
}
.pricing-dark-section .charts .chart.chart--featured {
  min-height: 350px;
  top: 0;
  margin: 0 auto;
}
.pricing-dark-section .charts .chart.chart--featured .popular {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  color: #b1b1b1;
  font-weight: 400;
}
.pricing-dark-section .charts .chart.chart--featured .quantity {
  margin-top: 15px;
}
.pricing-dark-section .charts .chart .quantity {
  text-align: center;
}
.pricing-dark-section .charts .chart .quantity .dollar {
  font-size: 19px;
  position: relative;
  top: -18px;
}
.pricing-dark-section .charts .chart .quantity .price {
  font-size: 49px;
}
.pricing-dark-section .charts .chart .quantity .period {
  font-size: 17px;
  position: relative;
  top: -8px;
  margin-left: 4px;
}
.pricing-dark-section .charts .chart .plan-name {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #777;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 15px;
  width: 90%;
  margin: 0 auto;
  margin-top: 8px;
}
.pricing-dark-section .charts .chart .specs {
  margin-top: 20px;
}
.pricing-dark-section .charts .chart .specs .spec {
  font-size: 15px;
  color: #353535;
  text-align: center;
  font-weight: 300;
  margin-bottom: 13px;
}
.pricing-dark-section .charts .chart .specs .spec .variable {
  color: #1fbee7;
  font-weight: 400;
}
.pricing-dark-section .charts .chart .btn-signup {
  margin-top: 20px;
  height: 38px;
}
.pricing-4-options-section {
  margin-top: 100px;
}
.pricing-4-options-section .header {
  text-align: center;
}
.pricing-4-options-section .header h3 {
  font-size: 24px;
  color: #444;
  line-height: 25px;
  font-weight: 500;
}
.pricing-4-options-section .header p {
  font-size: 16px;
  font-weight: 400;
  color: $ak-blue-light;
}
@media (min-width: 992px) {
  .pricing-4-options-section .container {
    max-width: 1060px;
  }
}
.pricing-4-options-section .charts {
  margin-top: 80px;
}
.pricing-4-options-section .charts .basic-plan {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(181, 181, 181, 0.3);
  padding: 20px 30px 10px 30px;
  text-align: center;
  width: 220px;
  position: absolute;
  right: 0;
  top: 20px;
}
@media (max-width: 991px) {
  .pricing-4-options-section .charts .basic-plan {
    float: none !important;
    left: 0 !important;
    top: 0 !important;
    margin: 0 auto;
    width: 55%;
    margin-bottom: 25px !important;
    position: relative;
    margin-top: 30px;
  }
  .pricing-4-options-section .charts .basic-plan.last {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 600px) {
  .pricing-4-options-section .charts .basic-plan {
    width: 90% !important;
  }
}
.pricing-4-options-section .charts .basic-plan .plan-name {
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  color: #444;
  width: 90%;
  margin: 0 auto;
  margin-top: 5px;
}
.pricing-4-options-section .charts .basic-plan .quantity {
  text-align: center;
  border-bottom: 1px solid #d5d5d5;
  margin-top: 5px;
}
.pricing-4-options-section .charts .basic-plan .quantity .dollar {
  font-size: 19px;
  position: relative;
  top: -18px;
}
.pricing-4-options-section .charts .basic-plan .quantity .price {
  font-size: 42px;
}
.pricing-4-options-section .charts .basic-plan .quantity .period {
  font-size: 17px;
  position: relative;
  top: -8px;
  margin-left: 4px;
}
.pricing-4-options-section .charts .basic-plan .description {
  margin-top: 15px;
  font-size: 13px;
  color: #444;
  line-height: 20px;
}
.pricing-4-options-section .charts .basic-plan .description a {
  display: block;
  margin-top: 20px;
  font-weight: 500;
  font-size: 15px;
}
.pricing-4-options-section .charts .chart {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(181, 181, 181, 0.3);
  padding: 20px 40px;
  position: relative;
  text-align: center;
  width: 280px;
  float: left;
  min-height: 315px;
  position: relative;
  top: 20px;
}
@media (min-width: 992px) {
  .pricing-4-options-section .charts .chart.featured {
    left: -25px;
  }
  .pricing-4-options-section .charts .chart.last {
    left: -45px;
  }
}
@media (max-width: 991px) {
  .pricing-4-options-section .charts .chart {
    float: none !important;
    left: 0 !important;
    top: 0 !important;
    margin: 0 auto;
    width: 55%;
    margin-bottom: 25px !important;
  }
  .pricing-4-options-section .charts .chart.last {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 600px) {
  .pricing-4-options-section .charts .chart {
    width: 90% !important;
  }
}
.pricing-4-options-section .charts .chart.featured {
  z-index: 999;
}
@media (min-width: 992px) {
  .pricing-4-options-section .charts .chart.featured {
    margin: 0 auto;
    top: 0;
    min-height: 445px;
  }
  .pricing-4-options-section .charts .chart.featured .btn-signup {
    margin-top: 32px;
  }
}
.pricing-4-options-section .charts .chart.featured .popular {
  position: absolute;
  top: 0;
  left: 0;
}
.pricing-4-options-section .charts .chart.featured .quantity {
  margin-top: 15px;
  padding-bottom: 5px;
}
.pricing-4-options-section .charts .chart .plan-name {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #444;
  width: 90%;
  margin: 0 auto;
  margin-top: 5px;
}
.pricing-4-options-section .charts .chart .quantity {
  text-align: center;
  border-bottom: 1px solid #d5d5d5;
  margin-top: 10px;
}
.pricing-4-options-section .charts .chart .quantity .dollar {
  font-size: 19px;
  position: relative;
  top: -18px;
}
.pricing-4-options-section .charts .chart .quantity .price {
  font-size: 49px;
}
.pricing-4-options-section .charts .chart .quantity .period {
  font-size: 17px;
  position: relative;
  top: -8px;
  margin-left: 4px;
}
.pricing-4-options-section .charts .chart .specs {
  margin-top: 20px;
}
.pricing-4-options-section .charts .chart .specs .spec {
  font-size: 15px;
  color: #353535;
  text-align: center;
  font-weight: 300;
  margin-bottom: 13px;
}
.pricing-4-options-section .charts .chart .specs .spec .variable {
  color: #1fbee7;
  font-weight: 400;
}
.pricing-4-options-section .charts .chart .btn-signup {
  margin-top: 20px;
  height: 38px;
}
.pricing-tabs-section {
  margin-top: 90px;
}
.pricing-tabs-section .header {
  text-align: center;
}
.pricing-tabs-section .header h3 {
  font-size: 23px;
  color: #444;
  line-height: 25px;
  font-weight: 500;
}
.pricing-tabs-section .header p {
  font-size: 16px;
  font-weight: 400;
  color: $ak-blue-light;
}
.pricing-tabs-section .tabs {
  background: #f8f8f8;
  text-align: center;
  border-radius: 3px;
  margin-top: 40px;
}
.pricing-tabs-section .tabs .tab {
  background-color: #f8f8f8;
  font-weight: 500;
  color: #555;
  display: inline-block;
  padding: 18px 25px;
  margin-top: 10px;
  cursor: pointer;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.pricing-tabs-section .tabs .tab.anually {
  padding: 18px 18px 18px 23px;
}
.pricing-tabs-section .tabs .tab.active {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 5px 5px 0 0;
  border-bottom: 0;
}
.pricing-tabs-section .tabs .tab span {
  position: relative;
  top: -2px;
}
.pricing-tabs-section .tabs .tab small {
  color: #32acda;
  margin-left: 3px;
}
.pricing-tabs-section .plans {
  margin-top: 40px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.pricing-tabs-section .plans .plan {
  border-right: 0;
  min-height: 220px;
  text-align: center;
}
.pricing-tabs-section .plans .plan:first-child {
  padding-right: 0;
}
.pricing-tabs-section .plans .plan:last-child {
  padding-left: 0;
}
.pricing-tabs-section .plans .plan.middle {
  border-right: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
  padding: 0;
}
.pricing-tabs-section .plans .plan .header {
  text-align: center;
  padding: 22px 25px 18px;
  border-bottom: 1px solid #e3e3e3;
  font-weight: 500;
  font-size: 15px;
}
.pricing-tabs-section .plans .plan .header .icon {
  color: #b7c5d8;
  font-size: 18px;
  position: relative;
  top: 2px;
  left: -4px;
}
.pricing-tabs-section .plans .plan .header .price {
  color: #3ca2e5;
  margin-top: 10px;
}
.pricing-tabs-section .plans .plan .specs {
  margin-top: 25px;
  padding: 0 10px 17px 10px;
}
.pricing-tabs-section .plans .plan .specs .spec {
  font-size: 15px;
  color: #666;
  margin-bottom: 13px;
}
.pricing-tabs-section .plans .plan .specs .spec .variable {
  color: #1fbee7;
  font-weight: 400;
}
.pricing-tabs-section .signup {
  margin-top: 50px;
  text-align: center;
}
.pricing-tabs-section .signup .message {
  text-align: center;
  margin-top: 20px;
  font-size: 13px;
  color: $ak-blue-light;
}
.pricing-comparison-section {
  overflow-x: scroll;
}
.pricing-comparison-section .divider {
  height: 1px;
  width: 100%;
  background-color: #ddd;
  position: relative;
  margin: 100px 0 80px;
}
.pricing-comparison-section .divider:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0, rgba(255, 255, 255, 0.75)),
      color-stop(0.5, rgba(250, 250, 250, 0)),
      color-stop(1, rgba(255, 255, 255, 0.75))
  );
  background-image: -webkit-linear-gradient(
      to right,
      rgba(255, 255, 255, 0.75) 0,
      rgba(250, 250, 250, 0) 50%,
      rgba(255, 255, 255, 0.75) 100%
  );
  background-image: -moz-linear-gradient(
      to right,
      rgba(255, 255, 255, 0.75) 0,
      rgba(250, 250, 250, 0) 50%,
      rgba(255, 255, 255, 0.75) 100%
  );
  background-image: -o-linear-gradient(
      to right,
      rgba(255, 255, 255, 0.75) 0,
      rgba(250, 250, 250, 0) 50%,
      rgba(255, 255, 255, 0.75) 100%
  );
  background-image: -ms-linear-gradient(
      to right,
      rgba(255, 255, 255, 0.75) 0,
      rgba(250, 250, 250, 0) 50%,
      rgba(255, 255, 255, 0.75) 100%
  );
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.75) 0,
      rgba(250, 250, 250, 0) 50%,
      rgba(255, 255, 255, 0.75) 100%
  );
}
.pricing-comparison-section .main-header {
  text-align: center;
  margin-top: 30px;
}
.pricing-comparison-section .main-header h3 {
  font-size: 24px;
  color: #444;
  line-height: 25px;
  font-weight: 500;
}
.pricing-comparison-section .main-header p {
  font-size: 16px;
  font-weight: 400;
  color: $ak-blue-light;
}
.pricing-comparison-section .chart {
  margin-top: 60px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  min-width: 930px;
}
@media (max-width: 991px) {
  .pricing-comparison-section .chart {
    margin-right: 50px;
  }
}
.pricing-comparison-section .chart .header {
  text-align: center;
  background: #fbfbfb;
  font-weight: 500;
  font-size: 18px;
  color: #444;
  padding: 13px 0;
  border-bottom: 1px solid #dfdfdf;
}
.pricing-comparison-section .chart .header .price {
  color: #1a80d9;
  position: relative;
  top: 3px;
  font-size: 14px;
}
.pricing-comparison-section .chart .features-col {
  width: 40%;
}
.pricing-comparison-section .chart .features-col .header {
  text-align: left;
  border-radius: 3px 3px 0 0;
  margin-bottom: 30px;
  padding: 23px 30px;
  font-size: 18px;
  height: 75px;
}
.pricing-comparison-section .chart .features-col .spec {
  margin-bottom: 32px;
  padding-left: 70px;
  font-size: 15px;
  position: relative;
}
.pricing-comparison-section .chart .features-col .spec .icon {
  position: absolute;
  font-size: 21px;
  color: #8aa6cc;
  left: 23px;
}
.pricing-comparison-section .chart .plan-col {
  width: 20%;
  border-left: 1px solid #dfdfdf;
}
.pricing-comparison-section .chart .plan-col.last .header {
  border-radius: 0 3px 0 0;
}
.pricing-comparison-section .chart .plan-col .spec {
  margin-top: 33px;
}
.pricing-comparison-section .chart .plan-col .spec .check {
  margin: 0 auto;
  display: block;
  max-width: 20px;
}
.pricing-comparison-section .chart .plan-col .btn-shadow {
  margin-bottom: 20px;
}
.pricing-comparison-section .message {
  text-align: center;
  font-size: 14px;
  color: #656565;
  margin-top: 30px;
}
.pricing-comparison-section .faq {
  margin-top: 80px;
}
.pricing-comparison-section .faq .header h3 {
  font-size: 20px;
  color: #454545;
  text-align: center;
  font-weight: 600;
}
.pricing-comparison-section .faq .questions {
  margin-top: 50px;
}
.pricing-comparison-section .faq .questions .question {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .pricing-comparison-section .faq .questions .question {
    width: 90%;
  }
}
.pricing-comparison-section .faq .questions .question strong {
  font-weight: 500;
  font-size: 16px;
  color: #454545;
}
.pricing-comparison-section .faq .questions .question p {
  color: #555;
  margin-top: 10px;
  font-size: 13px;
  line-height: 22px;
}


/* RIBBON BANNERS */

.ribbon {
  position: absolute;
  top: 0;
  right: 0;
}

/* reset certain elements (in case of conflicting CSS for classes, links, etc.) */
.ribbon .text,
.ribbon .banner,
.ribbon a {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.ribbon a {
  color: #fff;
  text-decoration: none;
}

.no-css-transforms .ribbon {
  font-size: 1em;
  position: relative;
  width: 100%;
}

.ribbon .banner {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  color: #fff;
  -webkit-font-smoothing: antialiased;
  display: block;
  float: right;
  position: relative;
  right: -14px;
  top: 22px;
  width: 100px;
}

.ribbon .banner::after,
.ribbon .banner::before {
  content: '';
  display: block;
  height: 12px;
  position: absolute;
  width: 30px;
}

.ribbon .banner::before {
  -webkit-transform: skewY(-45deg) translate(50%,15px);
  -moz-transform: skewY(-45deg) translate(50%,15px);
  -ms-transform: skewY(-45deg) translate(50%,15px);
  -o-transform: skewY(-45deg) translate(50%,15px);
  -webkit-transform-origin: 100% center;
  -moz-transform-origin: 100% center;
  -ms-transform-origin: 100% center;
  -o-transform-origin: 100% center;
  left: -45px;
}

.ribbon .banner::after {
  -webkit-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
  -moz-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
  -ms-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
  -o-transform: translate(100%,-100%) skewY(45deg) translateX(-58%);
  -webkit-transform-origin: 0 center;
  -moz-transform-origin: 0 center;
  -ms-transform-origin: 0 center;
  -o-transform-origin: 0 center;
  right: -17px;
}

.no-css-transforms .ribbon .banner {
  position: static;
  width: 100%;
  float: none;
  font-size: 10px;
}

.ribbon .text {
  position: relative;
  z-index: 2;
  padding: 6px 0px;
  font-size: 12px;
  font-weight: bold;
  min-height: 18px;
  line-height: 18px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.20);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ribbon .text::before,
.ribbon .text::after {
  content: '';
  display: block;
  height: 30px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.ribbon .text::before {
  -webkit-transform: translateX(-15%) skewX(-45deg);
  -moz-transform: translateX(-15%) skewX(-45deg);
  -ms-transform: translateX(-15%) skewX(-45deg);
  -o-transform: translateX(-15%) skewX(-45deg);
}

.ribbon .text::after {
  -webkit-transform: translateX(15%) skewX(45deg);
  -moz-transform: translateX(15%) skewX(45deg);
  -ms-transform: translateX(15%) skewX(45deg);
  -o-transform: translateX(15%) skewX(45deg);
}

.no-css-transforms .ribbon .text {
  height: 25px;
  padding: 3px;
}


/*--- RIBBON COLORS ---*/

/* mms blue */
.ribbon-blue-mms .banner::after,
.ribbon-blue-mms .banner::before {
  background-color: $ak-blue;
}
.ribbon-blue-mms .text::before,
.ribbon-blue-mms .text::after,
.no-css-transforms .ribbon-blue-mms .text {
  background-color: $ak-blue-dark;
}
