$ak-blue-light: #98CBD2;
$ak-blue: #4381BF;
$ak-blue-dark: #305B86;
$primary: $ak-blue-dark;
$secondary: $ak-blue;
$cyan: $ak-blue-light;
$dirty-white: #e9ecef;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import './ak-app.scss';
@import './pricing.scss';
