._panel_1nv7e_1 {
  background-color: #031b4e;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  position: fixed;
  right: 0;
  transition: opacity 1s ease-in-out;
  z-index: 1000;
}
._fadeout_1nv7e_12 {
  opacity: 0;
}
._wrap_1nv7e_16 {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 970px;
  padding: 24px 16px;
}
._text_1nv7e_24 {
  color: #fff;
  font-family: Sailec-Regular, system-ui, -apple-system, Segoe UI, Roboto,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
}
._link_1nv7e_33 {
  border-bottom: 1px dotted hsla(0, 0%, 100%, 0.6);
  color: #fff;
  text-decoration: none;
}
._button_1nv7e_39 {
  background-color: #0069ff;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: Sailec-Bold, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin: 24px 0 0;
  padding: 16px 32px 15px;
  text-align: center;
  transition: background-color 0.2s ease, color 0.2s ease;
  white-space: nowrap;
}
._button_1nv7e_39:focus,
._button_1nv7e_39:hover {
  background-color: #1633ff;
  color: #fff;
}
@media only screen and (min-width: 767px) {
  ._button_1nv7e_39 {
    margin: 0 0 0 24px;
  }
  ._wrap_1nv7e_16 {
    align-items: center;
    flex-direction: row;
  }
}
